<template>
    <div>
        <div class="set-container">
            <el-input placeholder="快递名称或快递编码" v-model="name" style="width: 300px" @change="searchBtn">
                <el-button slot="append" @click="searchBtn">搜索</el-button>
            </el-input>
            <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column prop="id" label="id" width="120"></el-table-column>
                <el-table-column prop="name" label="快递名称"></el-table-column>
                <el-table-column prop="code" label="快递编码"></el-table-column>
                <el-table-column prop="type" label="快递类型"></el-table-column>
                <el-table-column prop="city" label="是否为常用快递" align="center">
                    <template slot-scope="scope">
                        {{scope.row.is_commonly === 1 ? '是' : '否'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="setBtn(scope.row)">{{scope.row.is_commonly === 1 ? '取消' : '设为常用快递'}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-center"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           layout="prev, pager, next, jumper"
                           :total="listPages.total"
                           @current-change="pageCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { campanyIndex, campanyUpdate } from '@/config/apis.js'
    export default {
        name: "ExpressManage",
        data() {
            return {
                name: '',
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                }
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.name) {
                    params.name = this.name
                }
                campanyIndex(params).then(res => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                }).catch(err => {})
            },
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            searchBtn() {
                if (!this.name) {
                    return this.$message.warning('请输入快递名称或快递编码')
                }
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 设置为常用快递
            setBtn(val) {
                let params = {
                    id: val.id
                }
                if (val.is_commonly === 1) {
                    params.is_commonly = 0
                } else {
                    params.is_commonly = 1
                }
                campanyUpdate(params).then(res => {
                    this.$message.success(res.msg)
                    this.getList()
                }).catch(err => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
</style>